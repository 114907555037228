import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { init, browserTracingIntegration } from '@sentry/remix'
import { posthog } from 'posthog-js'
import { startTransition, StrictMode, useEffect, type ReactNode } from 'react'
import { hydrateRoot } from 'react-dom/client'

init({
  dsn: 'https://f996e1a65e116e83f078c223ebf42ea7@o4504577176305664.ingest.us.sentry.io/4508854584934400',
  tracesSampleRate: 1,
  integrations: [
    browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
})

const PostHogInit = (): ReactNode => {
  useEffect(() => {
    posthog.init('phc_QuIoTF5lEBBtB0wWQxIRsZA2A58joaW3qbREWaCOMvW', {
      api_host: 'https://i.m.app.terros.com',
      ui_host: 'https://us.posthog.com',
      person_profiles: 'identified_only',
    })
  }, [])
  return null
}

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
      <PostHogInit />
    </StrictMode>
  )
})
